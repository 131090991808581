import simg1 from '../images/services/icon-1.svg';
import simg2 from '../images/services/icon-2.svg';
import simg3 from '../images/services/icon-3.svg';
import simg4 from '../images/services/icon-4.svg';

import sSimg1 from '../images/service-single/1.jpg';
import sSimg2 from '../images/service-single/2.jpg';
import sSimg3 from '../images/service-single/3.jpg';
import sSimg4 from '../images/service-single/4.jpg';
import sSimg5 from '../images/service-single/5.jpg';
import sSimg6 from '../images/service-single/6.jpg';

const Services = [
   {
      id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      title: 'Web Designing',
      sub: 'Web Designing Company',
      slug: 'web-designing',
      description: 'We design visually appealing websites that offer great user experiences and are mobile-friendly.',
      info: 'At Euro Infotech, we specialize in creating stunning and responsive websites that help businesses establish a strong online presence. Our web design services are tailored to meet your specific needs, ensuring a user-friendly, mobile-optimized, and SEO-friendly website. We focus on developing websites that not only look great but also perform well on search engines. Our team uses the latest design trends, responsive web design techniques, and content management systems (CMS) to create websites that drive traffic and engage users effectively. We understand that web design is a crucial part of your digital marketing strategy, and we ensure your website stands out in the competitive online market.',
   },
   {
      id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      title: 'Web Development',
      sub: 'Web Development Company',
      slug: 'web-development',
      description: 'We build dynamic, responsive websites tailored to your business needs and ensure performance.',
      info: 'Euro Infotech offers expert web development services that ensure your website is not only functional but also optimized for SEO and user experience. Our web development solutions are crafted using the latest web technologies, programming languages, and frameworks to ensure high performance, security, and scalability. From custom-built websites to complex web applications, we create solutions that are responsive, fast-loading, and designed to perform well across all devices. Our team ensures your website is optimized for search engines, enhancing your online visibility and helping you stay ahead of the competition.',
   },
   {
      id: '03',
      sImg: simg3,
      sSImg: sSimg5,
      title: 'E-Commerce',
      sub: 'E-Commerce Development Company',
      slug: 'e-commerce-shopping-cart',
      description: 'We create secure, scalable e-commerce solutions with seamless shopping experiences for customers.',
      info: 'Euro Infotech specializes in developing secure, scalable, and SEO-friendly e-commerce platforms that provide an excellent shopping experience for both businesses and customers. Our e-commerce web development services ensure that your online store is optimized for search engines, mobile devices, and smooth user interaction. We focus on building customizable product catalogs, easy-to-use shopping carts, and seamless payment gateway integrations. Our team creates high-performance e-commerce websites that not only meet your business needs but also increase online visibility and sales. We ensure your e-commerce site is built with the latest technologies, optimized for speed, and ready to scale with your growing business.',
   },
   {
      id: '04',
      sImg: simg4,
      sSImg: sSimg4,
      title: 'Mobile Application',
      sub: 'Mobile App Development Company',
      slug: 'mobile-application-development',
      description: 'We develop user-friendly mobile apps for Android and iOS that drive engagement and growth.',
      info: 'Euro Infotech is a leading provider of mobile application development services for both Android and iOS platforms. We create mobile apps that are optimized for performance, user engagement, and SEO. Our development team works closely with clients to build apps that are not only feature-rich but also provide exceptional user experiences across all devices. Whether you need an app for business solutions, customer engagement, or e-commerce, we ensure that each mobile app is designed to meet your specific business goals. Our mobile apps are built to drive growth, improve user retention, and increase brand visibility in the competitive app marketplace.',
   },
   {
      id: '05',
      sImg: simg3,
      sSImg: sSimg3,
      title: 'Domain Registration',
      sub: 'Domain Registration Company',
      slug: 'domain-registration',
      description: 'We help you secure the ideal domain name to boost your brand’s visibility and online presence.',
      info: 'Euro Infotech offers professional domain registration services to help businesses choose and secure SEO-friendly domain names that enhance online visibility and brand recognition. A well-chosen domain name is a crucial element of your online marketing strategy, and our team ensures that your domain name is memorable, relevant to your business, and optimized for search engines. We assist in securing domain names that are aligned with your brand, business goals, and target audience, improving your chances of ranking higher in search results. Whether you are establishing a new brand or rebranding, we ensure a seamless domain registration process to strengthen your online presence.',
   },
   {
      id: '06',
      sImg: simg2,
      sSImg: sSimg6,
      title: 'Web Hosting',
      sub: 'Web Hosting Company',
      slug: 'web-hosting',
      description: 'We offer reliable, fast web hosting solutions to ensure your website’s performance and security.',
      info: 'Euro Infotech offers fast, reliable, and SEO-optimized web hosting solutions that provide superior performance, security, and scalability. Our hosting services are designed to ensure your website loads quickly, is secure from cyber threats, and offers excellent uptime, all of which are critical factors for SEO rankings. We provide a range of hosting options, including shared hosting, VPS hosting, and dedicated servers, to meet the needs of businesses of all sizes. With Euro Infotech’s web hosting services, your website will run smoothly, provide a better user experience, and be optimized for search engines to improve your online visibility and ranking.',
   },
];


export default Services;
