import pimg1 from '../images/project/1.jpg'
import pimg2 from '../images/project/2.jpg'
import pimg3 from '../images/project/3.jpg'
import pimg4 from '../images/project/4.jpg'
import pimg5 from '../images/project/5.jpg'
import pimg6 from '../images/project/6.jpg'
import pimg7 from '../images/project/7.jpg'
import pimg8 from '../images/project/8.jpg'
import pimg9 from '../images/project/9.jpg'
import pimg10 from '../images/project/10.jpg'
import pimg11 from '../images/project/11.jpg'
import pimg12 from '../images/project/12.jpg'
import pimg13 from '../images/project/13.jpg'
import pimg14 from '../images/project/14.jpg'
import pimg15 from '../images/project/15.jpg'
import pimg16 from '../images/project/16.jpg'
import pimg17 from '../images/project/17.jpg'
import pimg18 from '../images/project/18.jpg'
import pimg19 from '../images/project/19.jpg'
import pimg20 from '../images/project/20.jpg'
import pimg21 from '../images/project/21.jpg'
import pimg22 from '../images/project/22.jpg'
import pimg23 from '../images/project/23.jpg'
import pimg24 from '../images/project/24.jpg'
import pimg25 from '../images/project/25.jpg'
import pimg26 from '../images/project/26.jpg'




const Projects = [
    {
        id: '1',
        title: 'CREDAI Kerala',
        subtitle: 'Development',
        slug: '1',
        pimg1: pimg1,
        date: '',
        location: 'Keral',
        type: 'Investment planning',
        service: 'It, Company',
        duration: '6 Months',
        add: 'Millington, Ave, TN 38053',
        architect: 'MR.UDAYA KUMAR.V',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://credaikerala.com/'
    },
    {
        id: '2',
        title: 'MACHNHOM',
        subtitle: 'Product',
        slug: '2',
        pimg1: pimg2,
        date: '',
        location: 'Austria',
        type: 'Investment planning',
        service: 'It, Company',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'MR.UDAYA KUMAR.V',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://macnhomsystems.com/'
    },
    {
        id: '3',
        title: 'Online Kochi',
        subtitle: 'E-Store',
        slug: '3',
        pimg1: pimg3,
        date: '',
        location: 'Austria',
        type: 'Investment planning',
        service: 'It, Company',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://onlinekochi.com/'
    },
    {
        id: '4',
        title: 'Toc H School',
        subtitle: 'Educational',
        slug: '4',
        pimg1: pimg4,
        date: '',
        location: 'Austria',
        type: 'Investment planning',
        service: 'It, Company',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://tochschool.com/'
    },
    {
        id: '5',
        title: 'Jerry Amaldev',
        subtitle: 'Portfolio',
        slug: '5',
        pimg1: pimg5,
        date: '',
        location: 'Austria',
        type: 'Investment planning',
        service: 'It, Company',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://jerryamaldev.com/'
    },

    {
        id: '6',
        title: 'Kumbalangy',
        subtitle: 'Tourism',
        slug: '5',
        pimg1: pimg6,
        date: '',
        location: 'Kumbalangy',
        type: 'Tourism',
        service: 'Tourism',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://kumbalangy.com/'
    },

    {
        id: '7',
        title: 'St. Joseph College',
        subtitle: 'Education',
        slug: '5',
        pimg1: pimg7,
        date: '',
        location: 'Kumbalangy',
        type: 'College',
        service: 'Education',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://www.stjosephcte.in/'
    },

    {
        id: '8',
        title: 'Mysore Shankar',
        subtitle: 'Ayurveda Pharmacy',
        slug: '5',
        pimg1: pimg8,
        date: '',
        location: 'Karnataka',
        type: 'Ayurveda Pharmacy',
        service: 'Pharmacy',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://mysoreshankar.com/'
    },

    {
        id: '9',
        title: 'XL Interiors Pvt Ltd',
        subtitle: 'Interior Designers',
        slug: '5',
        pimg1: pimg9,
        date: '',
        location: 'Maradu',
        type: 'interior designers',
        service: 'interior designers',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://xlinterior.com/'
    },

    {
        id: '10',
        title: 'City Decor',
        subtitle: 'Fabrications',
        slug: '5',
        pimg1: pimg10,
        date: '',
        location: 'Palarivattom',
        type: 'interior designers',
        service: 'interior designers',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://citydecor.net/'
    },

    {
        id: '11',
        title: 'Kakkathuruthu',
        subtitle: 'Tourism',
        slug: '5',
        pimg1: pimg11,
        date: '',
        location: 'Aroor',
        type: 'Tourism',
        service: 'Tourism',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://kakkathuruthu.com/'
    },

    {
        id: '12',
        title: 'PKM FINE FOODS',
        subtitle: 'Processed foods',
        slug: '5',
        pimg1: pimg12,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://pkmfinefoods.com/'
    },

    {
        id: '13',
        title: 'Kadamakkudy',
        subtitle: 'Tourism',
        slug: '5',
        pimg1: pimg13,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://kadamakkudy.net/'
    },

    {
        id: '14',
        title: 'Miamihotelshj',
        subtitle: 'Tourism',
        slug: '5',
        pimg1: pimg14,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://miamihotelshj.com/'
    },

    {
        id: '15',
        title: 'Notesnchords',
        subtitle: 'Portfolio',
        slug: '5',
        pimg1: pimg15,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://notesnchords.com/'
    },

    {
        id: '16',
        title: 'KMJpublicschool',
        subtitle: 'Education',
        slug: '5',
        pimg1: pimg16,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://kmjpublicschool.com/'
    },

    {
        id: '17',
        title: 'Bituminexcochin',
        subtitle: 'Construction',
        slug: '5',
        pimg1: pimg17,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://bituminexcochin.com/'
    },

    {
        id: '18',
        title: 'Southmarkrubber',
        subtitle: 'Product',
        slug: '5',
        pimg1: pimg18,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://southmarkrubber.com/'
    },

    {
        id: '19',
        title: 'Realestateworld',
        subtitle: 'Sales',
        slug: '5',
        pimg1: pimg19,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'http://realestateworld.in/'
    },

    {
        id: '20',
        title: 'Nirmala College',
        subtitle: 'Sales',
        slug: '5',
        pimg1: pimg20,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://nirmalatrainingcollege.com/'
    },

    {
        id: '21',
        title: 'Royalmist',
        subtitle: 'Tourism',
        slug: '5',
        pimg1: pimg21,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://royalmist.in/'
    },

    {
        id: '22',
        title: 'Bouque International',
        subtitle: 'E-store',
        slug: '5',
        pimg1: pimg22,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://bouqueinternational.com/'
    },

    {
        id: '23',
        title: 'Perfectrealestates',
        subtitle: 'Sales',
        slug: '5',
        pimg1: pimg23,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://perfectrealestates.com/'
    },

    {
        id: '24',
        title: 'tntshilpa',
        subtitle: 'Sales',
        slug: '5',
        pimg1: pimg24,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://tntshilpa.com/'
    },

    {
        id: '25',
        title: 'Euroinfoacademy',
        subtitle: 'Education',
        slug: '5',
        pimg1: pimg25,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://euroinfoacademy.com/'
    },

    {
        id: '26',
        title: 'Agnelindustries',
        subtitle: 'Sales',
        slug: '5',
        pimg1: pimg26,
        date: '',
        location: 'Aikaranad South',
        type: 'Processed foods',
        service: 'Processed foods',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'It, Company',
        fax: ' 568 746 987',
        link: 'https://agnelindustries.com/'
    },
]
export default Projects;