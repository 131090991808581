import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import About from '../../components/about/about';
import VideosSection from '../../components/VideosSection/VideosSection';
import TestimonialSection from '../../components/TestimonialSection/TestimonialSection';
import CtaSection from '../../components/CtaSection/CtaSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.svg'
import Phone from '../../images/telephone.svg'
import CursorMaus from '../../components/CursorMaus/CursorMaus';
import { Helmet } from 'react-helmet';
const AboutPage = () => {
    return (
        <Fragment>

            <Helmet>
            
                <title>About | Euroinfotech Software Solutions</title>
                <meta name="description" content="Euro infotech Software Solutions Offers  Premium Website at Low Cost, E-Commerce Development, Responsive  web design, affordable Price Website  iOS, iPhone,  Android  Apps development, SEO, Web devolopment company in  Kochi, Cochin, Kerala, India, about " />
                <meta name="keywords" content="Website designing Company In Kochi, Kerala, Cochin,Low Cost Premium Website, affordable Price Website, E-Commerce Development,SEO" />
      
            </Helmet>

            <Navbar Logo={Logo} hclass={"wpo-site-header s3"} telephone={Phone} />
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            <About />
            <VideosSection />
            <TestimonialSection />
            {/* <CtaSection /> */}
            <BlogSection />
            <Footer />
            <Scrollbar />
            <CursorMaus />
        </Fragment>
    )
};
export default AboutPage;
