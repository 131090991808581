import React from "react";
import { Link } from "react-router-dom";
import blogs from '../../api/blogs'
import SectionTitle from "../SectionTitle/SectionTitle";

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogSection = (props) => {




    return (

        <section className="blog-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <SectionTitle subtitle="Packages" title="Choose Your Perfect Plan" />
                    </div>
                </div>
                <div className="row">
                    {blogs.map((blog, bkye) => (
                        <div className="col col-lg-4 col-md-6 col-12" key={bkye}>
                            <div className="blog-card">
                                <div className="image">
                                    <img src={blog.screens} alt="" />
                                    <span>{blog.catagory}</span>
                                </div>
                                <div className="content">
                                <h2>{blog.title}</h2>
                                    
                                        <p>{blog.create_at}</p>
                                        
                                        <div className="hero-btn">
                                    
                            <a 
                                href="https://api.whatsapp.com/send?phone=919847931669" 
                                className="theme-btn" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                Message <i className="ti-arrow-right"></i>
                            </a>

                            
                        </div>
                                    
                                    {/* <div className="link">
                                        <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}><span>Continue Reading</span>
                                            <i className="ti-arrow-right"></i></Link>
                                    </div> */}
                                </div>
                                
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
}

export default BlogSection;










