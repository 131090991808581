import React, { Fragment, useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Services from '../../api/Services';
import ServiceSidebar from './sidebar';
import simg1 from '../../images/service-single/img-1.jpg';
import simg2 from '../../images/service-single/img-2.jpg';
import Footer from '../../components/footer/Footer';
import CursorMaus from '../../components/CursorMaus/CursorMaus';
import Logo from '../../images/logo-2.svg';
import Phone from '../../images/telephone.svg';
import Accordion from './accordion';
import { Helmet } from 'react-helmet';


const ServiceSinglePage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [serviceDetails, setServiceDetails] = useState(null);

  useEffect(() => {
    const service = Services.find((item) => item.slug === slug);
    if (service) {
      setServiceDetails(service);
    } else {
      // If no service found, display a message and prevent further rendering
      setServiceDetails(null);
    }
  }, [slug]);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  if (!serviceDetails) {
    return (
      <div className="service-not-found">
        <h2>Service Not Found</h2>
        <p>The service you're looking for does not exist or has been moved.</p>
        <Link to="/services">Back to All Services</Link>
      </div>
    );
  }

  return (
    <Fragment>

      <Helmet>

        <title>{serviceDetails.title} | Euroinfotech Software Solutions</title>
        <meta name="description" content={serviceDetails.description} />
        <meta name="keywords" content="Website designing Company In Kochi, Kerala, Cochin,Low Cost Premium Website, affordable Price Website, E-Commerce Development,SEO, domain registration" />
        <meta property="og:title" content={serviceDetails.title} />
        <meta property="og:description" content={serviceDetails.description} />
        
      </Helmet>

      <Navbar Logo={Logo} hclass={"wpo-site-header s3"} telephone={Phone} />
      <PageTitle pageTitle={serviceDetails.sub} pagesub={'Service'} />
      <section className="service-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="service-single-wrap">
                <div className="service-single-content">
                  <img src={serviceDetails.sSImg} alt={serviceDetails.title} />
                  <div className="service-single-content-des">
                    <h2>{serviceDetails.title}</h2>
                    <p>{serviceDetails.info}</p>
                    <div className="service-single-sub-img">
                      <ul>
                        <li><img src={simg1} alt="" /></li>
                        <li><img src={simg2} alt="" /></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="solutions-section">
                  <h2>Our Solutions</h2>
                  <div className="row">
                    {Services.slice(0, 3).map((sitem, sky) => (
                      <div className="col-lg-4 col-md-6 col-12" key={sky}>
                        <div className="solutions-item">
                          <div className="solutions-icon">
                            <div className="icon">
                              <img src={sitem.sImg} alt={sitem.title} />
                            </div>
                          </div>
                          <div className="solutions-text">
                            <h2><Link onClick={ClickHandler} to={`/service-single/${sitem.slug}`}>{sitem.title}</Link></h2>
                            <p>{sitem.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="benefits-section">
                  <h2>Benefits</h2>
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="benefits-item">
                        <Accordion />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
      <CursorMaus />
    </Fragment>
  );
};

export default ServiceSinglePage;
