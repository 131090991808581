import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import image1 from '../../images/testimonial/1.jpg';
import image2 from '../../images/testimonial/2.jpg';
import image3 from '../../images/testimonial/3.jpg';

import Shape1 from "../../images/testimonial/star.svg";
import Shape2 from "../../images/testimonial/star-1.svg";
import Shape3 from "../../images/testimonial/quote.png";

const TestimonialData = [
    {
        id: "01",
        text: "Euro Infotech team was exceptional in handling our project. Our website was no easy task, given the many complex design and functionality requirements. The team ensured that all of our needs were met promptly, with quick response and diligent follow-ups. They were highly attentive to detail, communication was consistent throughout the process.",
        subtitle: "Chairman of Mac-N-Hom",
        title: "MR.UDAYA KUMAR.V",
        image: image1
    },
    {
        id: "02",
        text: "The developers at Euro Infotech took the time to fully understand our business objectives. They customized their approach to ensure that their solutions aligned perfectly with our vision. The project was delivered on time and within budget, and we were impressed by their commitment to communication and updates, making the entire process smooth and efficient.",
        subtitle: "Musician",
        title: "Jerry Amaldev",
        image: image2
    },
    {
        id: "03",
        text: "A big thanks to Euro Infotech for creating and branding onlinekochi.com, which has become a top E-store in Kochi. The design team worked meticulously to create a site that perfectly aligned with our target market and budget. Their professional approach and attention to detail helped bring our vision to life, and the end result exceeded expectations.",
        subtitle: "Manager",
        title: "Manimohan",
        // image: image3
    }
]



const TestimonialSection = () => {
    const ClickHandlar = () => {
        window.scrollTo(10, 0);
    }

    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        infinite: true,
        responsive: [{
            breakpoint: 1199,
            settings: {
                dots: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                dots: true,
            }
        },

        ]
    };
    return (
        <section className="testimonial-section section-padding">
            <div className="container">
                <div className="row align-content-center">
                    <div className="col-xl-4 col-12">
                        <div className="section-title">
                            <span>Testimonial</span>
                            <h2>Our Latest Case Studies</h2>
                            <p>Reach out to us for personalized consultations and to discuss how we can help bring your ideas to life. We're here to assist you with your unique business needs and goals.</p>
                            <div className="testimonial-btn">
                                <Link to="/contact" className="theme-btn" onClick={ClickHandlar}> Get in Touch <i className="ti-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-12">
                        <div className="testimonial-slider">
                            <Slider {...settings}>
                                {TestimonialData.map((Testimonials, tky) => (
                                    <div className="item" key={tky}>
                                        <div className="client">
                                            <div className="image">
                                                <img src={Testimonials.image} alt="" />
                                            </div>
                                            <div className="text">
                                                <h2>{Testimonials.title}</h2>
                                                <span>{Testimonials.subtitle}</span>
                                            </div>
                                        </div>
                                        <div className="reting">
                                            <ul>
                                                <li><img src={Shape1} alt="" /></li>
                                                <li><img src={Shape1} alt="" /></li>
                                                <li><img src={Shape1} alt="" /></li>
                                                <li><img src={Shape1} alt="" /></li>
                                                <li><img src={Shape1} alt="" /></li>
                                            </ul>
                                            <span>(5)</span>
                                        </div>
                                        <p>{Testimonials.text}</p>
                                        <div className="icon">
                                            <img src={Shape3} alt="" />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialSection







