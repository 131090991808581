import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import ProjectSectionGd from '../../components/ProjectSection/ProjectSectionGd';
import TestimonialSection from '../../components/TestimonialSection/TestimonialSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo-2.svg'
import Phone from '../../images/telephone.svg'
import CursorMaus from '../../components/CursorMaus/CursorMaus';
import { Helmet } from 'react-helmet';
const ProjectPage = () => {
    return (
        <Fragment>

            <Helmet>
            
                <title>Portfolio | Euroinfotech Software Solutions</title>
                <meta name="description" content="Euro infotech Software Solutions Offers  Premium Website at Low Cost, E-Commerce Development, Responsive  web design, affordable Price Website  iOS, iPhone,  Android  Apps development, SEO, Web devolopment company in  Kochi, Cochin, Kerala, India, projects  " />
                <meta name="keywords" content="Website designing Company In Kochi, Kerala, Cochin,Low Cost Premium Website, affordable Price Website, E-Commerce Development,SEO, Projects" />
      
            </Helmet>

            <Navbar Logo={Logo} hclass={"wpo-site-header s3"} telephone={Phone} />
            <PageTitle pageTitle={'Portfolio'} pagesub={'Portfolio'} />
            <ProjectSectionGd hclass={"project-section"} />
            <Footer />
            <Scrollbar />
            <CursorMaus />
        </Fragment>
    )
};
export default ProjectPage;
