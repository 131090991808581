// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import recent1 from "../images/recent-posts/img-1.jpg";
import recent2 from "../images/recent-posts/img-2.jpg";
import recent3 from "../images/recent-posts/img-3.jpg";


import blogSingleImg1 from "../images/blog/1.jpg";
import blogSingleImg2 from "../images/blog/2.jpg";
import blogSingleImg3 from "../images/blog/3.jpg";



const blogs = [
    {
        id: '1',
        title: 'Small Business Websites @ 7500 ₹ Onwards',
        catagory: 'Websites',
        slug: '1',
        description: 'It has survived not only five but also the leap intona electronic typesetting remaining unchanged.',
        create_at: 'Domain Registration, Server Space(Hosting), Home Page / Services / Product, Contact Us',
        name:"",
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: blogSingleImg1,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
    {
        id: '2',
        title: 'E-Commerce Websites @ 25000 ₹ Onwards',
        catagory: 'Websites',
        slug: '2',
        description: 'It has survived not only five but also the leap intona electronic typesetting remaining unchanged.',
        create_at: 'E-Commerce Websites, Realestate Websites, E-Ticketing System, Tour Packages',
        name:"",
        screens: blogImg2,
        recent: recent2,
        blogSingleImg: blogSingleImg2,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
 
    {
        id: '3',
        title: 'Mobile Application @ 25000 ₹ Onwards',
        catagory: 'Applications',
        slug: '3',
        description: 'Realestate App, E- Ticketing System, Tour Package App',
        create_at: 'Mobile Applications, Realestate App, E- Ticketing System, Tour Package App',
        name:"",
        screens: blogImg3,
        recent: recent3,
        blogSingleImg: blogSingleImg3,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
 
 
   

];
export default blogs;