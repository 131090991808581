import React, { Fragment } from 'react';
import Slider from 'react-slick';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { Link, useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo-2.svg'
import Phone from '../../images/telephone.svg'
import CursorMaus from '../../components/CursorMaus/CursorMaus';

import Projects from '../../api/projects';
import Contact from '../ServiceSinglePage/contact';

import Simg1 from "../../images/project-single/2.jpg"
import Simg2 from "../../images/project-single/3.jpg"

import Ssilde1 from "../../images/project-single/1.jpg"
import Ssilde2 from "../../images/project-single/4.jpg"



const ProjectSinglePage = (props) => {
    const { slug } = useParams()

    const ProjectSingle = Projects.find(item => item.slug === slug)


    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                },
            }

        ],
    };
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header s3"} telephone={Phone} />
            <PageTitle pageTitle={ProjectSingle.title} pagesub={'Project'} />
            <section className="project-single-area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="project-single-wrap">
                                <div className="project-single-item">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <div className="project-single-title">
                                                <h3>{ProjectSingle.title}</h3>
                                            </div>
                                            <p>Euro Infotech is committed to delivering innovative and results-driven digital solutions. With a deep focus on technology and customer satisfaction, we ensure that each project is not just a product, but a success story. Our team of experts tailors every design and functionality to meet your business goals, while staying on time and within budget.</p>
<p>By combining creativity, technical expertise, and business insight, we help clients across various industries achieve scalable and sustainable growth. At Euro Infotech, we build long-term relationships with our clients and provide continuous support to help them thrive in an ever-evolving digital landscape.</p>

                                        </div>
                                        <div className="col-lg-5">
                                            <div className="project-single-content-des-right">
                                                <ul>
                                                    <li>Location :<span>{ProjectSingle.location}</span></li>
                                                    <li>Client :<span>{ProjectSingle.title}</span></li>
                                                    <li>Architect :<span>{ProjectSingle.architect}</span></li>
                                                    <li>Project Type :<span>{ProjectSingle.type}</span></li>
                                                    <li>Duration :<span>{ProjectSingle.duration}</span></li>
                                                    <li>Completion :<span>15 NOV 2024</span></li>
                                                    <li>Share :<span>{ProjectSingle.service}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-single-main-img">
                                        <Slider {...settings}>
                                            <img src={Ssilde1} alt="img" />
                                            <img src={Ssilde2} alt="img" />
                                            <img src={Ssilde1} alt="img" />
                                        </Slider>
                                    </div>
                                </div>
                                <div className="project-single-item list-widget">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="project-single-title">
                                                <h3>Our Strategies</h3>
                                            </div>
                                            <p>At Euro Infotech, we approach every project with a clear strategy that drives results. Our goal is to create tailor-made solutions that address the unique challenges of our clients, using the best tools and technologies available. Here's a glimpse of our strategies:</p>
                                                <ul>
                                                    <li>In-depth market research to understand client requirements and target audience.</li>
                                                    <li>Seamless collaboration between designers, developers, and stakeholders.</li>
                                                    <li>Agile methodologies to ensure flexible and efficient project delivery.</li>
                                                    <li>Continuous monitoring and optimization to ensure long-term success.</li>
                                                    <li>Customer-first approach, ensuring all solutions align with business objectives.</li>
                                                </ul>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="project-single-item-quote">
                                            <p>"Euro Infotech’s approach to digital solutions is unmatched. They took the time to understand our business needs and delivered an exceptional product that exceeded our expectations. Their team’s professionalism and attention to detail have been a key factor in our ongoing success."</p>
<span>MR.UDAYA KUMAR.V - <span>Chairman of Mac-N-Hom</span></span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-single-item">
                                    <div className="project-single-title">
                                        <h3>Our approach</h3>
                                    </div>
                                    <p>Euro Infotech’s approach is rooted in understanding the full scope of your business goals. We combine creativity, technology, and strategy to craft solutions that not only meet the project requirements but also exceed expectations. Our dedicated team ensures that your project is delivered on time, with ongoing support post-launch to ensure sustained growth and innovation.</p>

                                </div>
                                <div className="project-single-gallery">
                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="p-details-img">
                                                <img src={Simg1} alt="img" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="p-details-img">
                                                <img src={Simg2} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-single-item list-widget">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="project-single-title">
                                                <h3>Resieved goals</h3>
                                            </div>
                                            <ul>
                                                <li>Enhanced user engagement through a seamless and responsive website design.</li>
                                                <li>Improved site performance and speed, resulting in a better user experience.</li>
                                                <li>Increased conversion rates with optimized e-commerce functionality.</li>
                                                <li>Developed a scalable platform to support future growth and new features.</li>
                                            </ul>

                                        </div>
                                        <div className="col-lg-6 list-widget-s">
                                            <div className="project-single-title">
                                                <h3>Results</h3>
                                            </div>
                                            <ul>
                                                <li>Achieved a 40% increase in website traffic within the first three months.</li>
                                                <li>Boosted sales by 25% due to improved user experience and navigation.</li>
                                                <li>Enhanced customer satisfaction through fast and secure payment systems.</li>
                                                <li>Strengthened brand reputation with a modern, visually appealing website.</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-single-item">
                                    <div className="project-single-title">
                                        <h3>Related Projects</h3>
                                    </div>
                                    <div className="project-single-item">
                                        <div className="project-contact-area">
                                            <div className="contact-title">
                                                <h2>Have project in mind? Let's discuss</h2>
                                                <p>Get in touch with us to see how we can help you with your project</p>
                                            </div>
                                            <div className="contact-form-area">
                                                <Contact />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
            <CursorMaus />
        </Fragment>
    )
};
export default ProjectSinglePage;


