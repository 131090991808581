import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './style.css';

const Scrollbar = () => {
    return (
        <div className="col-lg-12">
            <div className="header-menu">
                <ul className="smothscroll">
                    {/* WhatsApp Button */}
                    <li>
                        <a 
                            href="https://api.whatsapp.com/send?phone=919847931669" // Replace with the actual WhatsApp number in international format
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="ti-comment-alt"></i> {/* Use Themify's ti-whatsapp icon */}
                        </a>
                    </li>

                    {/* Scroll to Top Button */}
                    <li>
                        <AnchorLink href="#scrool">
                            <i className="ti-arrow-up"></i> {/* Keep using ti-arrow-up for scroll to top */}
                        </AnchorLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Scrollbar;
