import { useState } from "react";



const Accordion = () => {
    const [OpenItem, setOpenItem] = useState(null);


    const handleItemClick = (itemId) => {
        setOpenItem(OpenItem === itemId ? null : itemId);
    };

    const AccordionData = [
        {
            "id": "heading1",
            "title": "We Build Websites That Deliver Measurable Results",
            "content": "We don't just create visually appealing websites; we engineer them for performance. Our focus is on driving tangible results for your business. We leverage data-driven strategies, proven SEO techniques, and user-centric design principles to ensure your website attracts, engages, and convert your target audience. We track and analyze key metrics, providing you with clear insights into your website's impact."
        },
        {
            "id": "heading2",
            "title": "We Guarantee a Seamless and Efficient Project Experience",
            "content": "We understand that your time is valuable. That's why we've streamlined our web design process to be as efficient and stress-free as possible. You'll work with a dedicated project manager who will keep you informed and involved every step ofthe way. We prioritize clear communication, transparent workflows, and timely delivery. We handle the complexities, so you can focus on your business."
        },
        {
            "id": "heading3",
            "title": "We Design Websites Built for Long-Term Success.",
            "content": "We don't build temporary solutions. We create websites that are designed for scalability, security, and long-term growth. We utilize cutting-edge technologies and adhere to industry best practices to ensureyour website remains relevant and effective. We offer comprehensive maintenance and support packages, ensuring your website stays up-to-date and performs optimally for years to come."
        },
        {
            "id": "heading4",
            "title": "We Craft Unique, Brand-Centric Digital Experiences",
            "content": "We believe your website should be atrue reflection of your brand. We take the time to understand your company's values, mission, and target audience. Our design process is collaborative and focused on creating a unique digital experience that resonates with your customers. We combine creativity with strategic thinking to develop websites that not only look great but also drive meaningful engagement."
        },
    ]


    return (
        <div className="accordion">
            {AccordionData.map((item =>
                <div className="accordion-item" key={item.id} >
                    <h3 className="accordion-header" id={item.id}>
                        <button className={`accordion-button ${OpenItem === item.id ? '' : 'collapsed'}`} type="button" onClick={() => handleItemClick(item.id)}>
                            {item.title}
                        </button>
                    </h3>
                    <div id={`collapse${item.id}`} className={`accordion-collapse collapse ${OpenItem === item.id ? 'show' : ''}`} aria-labelledby={item.id}>
                        <div className="accordion-body">
                            <p>{item.content}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

}

export default Accordion;