import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";
import Services from '../../api/Services';  // Import Services data
import './style.css';

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home'   
    },
    {
        id: 6,
        title: 'About',
        link: '/about',
    },
    {
        id: 7,
        title: 'Services',
        link: '/services',
        submenu: Services.map(service => ({
            id: service.Id,
            title: service.title,
            link: `/service-single/${service.slug}`
        }))
    },
    {
        id: 8,
        title: 'Portfolio',
        link: '/project',
        // submenu: [
        //     {
        //         id: 81,
        //         title: 'Portfolio',
        //         link: '/project',
        //     },
        //     {
        //         id: 82,
        //         title: 'Portfolio Single',
        //         link: '/project-single/1'
        //     },
        // ]
    },
    {
        id: 88,
        title: 'Contact',
        link: '/contact',
    }
];

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        return (
                            <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                {item.submenu ? 
                                    <Fragment>
                                        <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                                            {item.title}
                                            <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                        </p>
                                        <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                            <List className="subMenu">
                                                {item.submenu.map((submenu, i) => (
                                                    <ListItem key={i}>
                                                        <NavLink onClick={ClickHandler} className="active" to={submenu.link}>
                                                            {submenu.title}
                                                        </NavLink>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                    : <NavLink className="active" to={item.link}>
                                        {item.title}
                                    </NavLink>
                                }
                            </ListItem>
                        )
                    })}
                </ul>
            </div>

            <div className="showmenu mobail-menu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;
