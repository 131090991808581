import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import FunfactSecion from '../../components/FunfactSection/FunfactSection';
import About from '../../components/about/about';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import VideosSection from '../../components/VideosSection/VideosSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import TestimonialSection from '../../components/TestimonialSection/TestimonialSection';
// import CtaSection from '../../components/CtaSection/CtaSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import CursorMaus from '../../components/CursorMaus/CursorMaus';
import Phone from '../../images/telephone.svg';
import Logo from '../../images/logo.svg';
const HomePage = () => {
    return (
        <Fragment>

            <Helmet>
            
            <title>Euro infotech Software Solutions | Website Design & Development in Kochi | Affordable SEO & E-Commerce Solutions</title>
            <meta name="description" content="Euro Infotech Software Solutions offers premium website design, e-commerce development, SEO, and app development (iOS, Android) at affordable prices in Kochi, Kerala. Get responsive and custom web solutions today." />
            <meta name="keywords" content="website design, e-commerce development, SEO services, app development, iOS app development, Android app development, web development, affordable websites, Kochi, Kerala, Cochin" />
    
              
            </Helmet>

            <Navbar Logo={Logo} telephone={Phone} hclass={"wpo-site-header s1"} />
            <Hero />
            <FunfactSecion hclass="funfact-section" />
            <About />
            <ServiceSection hclass={'services-section'} />
            <VideosSection />
            <BlogSection />
            <ProjectSection hclass={"project-section"} />
            <TestimonialSection />
            {/* <CtaSection /> */}
            
            <Footer />
            <Scrollbar /> 
            <CursorMaus/> 
        </Fragment>
    )
};
export default HomePage;