import React, { useState } from 'react';
import SectionTitle from "../SectionTitle/SectionTitle";
import ProjectCard from './ProjectCard';
import Projects from "../../api/projects"; // Ensure this file contains the list of projects

const ProjectSectionGd = (props) => {
    // Pagination state: initialize to the first page
    const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 20; // Show 10 projects per page

    // Calculate the slice for the current page
    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const currentProjects = Projects.slice(indexOfFirstProject, indexOfLastProject);

    // Calculate total number of pages
    const totalPages = Math.ceil(Projects.length / projectsPerPage);

    // Functions to handle "Next" and "Previous" page navigation
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1); // Increment page
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1); // Decrement page
        }
    };

    return (
        <section className={"section-padding " + props.hclass}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <SectionTitle subtitle="Recent Projects" title="Our Latest Projects" />
                    </div>
                </div>

                {/* Grid Container */}
                <div className="project-grid">
                    {currentProjects.map((item, Pindx) => (
                        <div className="project-grid-item" key={Pindx}>
                            <ProjectCard 
                                Pimg={item.pimg1} 
                                Psubtitle={item.subtitle} 
                                Ptitle={item.title} 
                                Pslug={item.slug} 
                                Plink={item.link} 
                            />
                        </div>
                    ))}
                </div>

                {/* Pagination Controls */}
                <div className="pagination-controls">
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </div>
            </div>

            {/* Styles */}
            <style jsx>{`
                .project-grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr); /* 2 projects per row */
                    gap: 20px; /* Space between projects */
                    margin-top: 30px;
                }

                .project-grid-item {
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    transition: transform 0.3s ease;
                }

                .project-grid-item:hover {
                    transform: translateY(-10px); /* Slight hover effect */
                }

                .pagination-controls {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                    gap: 20px;
                }

                button {
                    padding: 10px 20px;
                    font-size: 16px;
                    cursor: pointer;
                    border: none;
                    background-color: #007bff;
                    color: white;
                    border-radius: 5px;
                }

                button:disabled {
                    background-color: #ccc;
                    cursor: not-allowed;
                }

                @media (max-width: 992px) {
                    .project-grid {
                        grid-template-columns: 1fr; /* 1 project per row on smaller screens */
                    }
                }

                @media (max-width: 575px) {
                    .project-grid {
                        grid-template-columns: 1fr; /* 1 project per row on extra small screens */
                    }
                }
            `}</style>
        </section>
    );
};

export default ProjectSectionGd;
