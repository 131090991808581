import Picon from "../../images/project/icon-arrow.svg"
import Picon1 from "../../images/project/icon-bg.svg"

const ProjectCard = ({ Pimg, Psubtitle, Ptitle, Plink }) => {
    return (
        <div className="project-card">
            <div className="image">
                <img src={Pimg} alt="" />
            </div>
            <div className="content">
                <span>{Psubtitle}</span>
                <h2>{Ptitle}</h2>
                {/* Direct anchor link */}
                <a href={Plink} target="_blank" rel="noopener noreferrer" className="project-link">
                    <div className="icon">
                        <img src={Picon} alt="" />
                    </div>
                    <div className="shape">
                        <img src={Picon1} alt="" />
                    </div>
                </a>
            </div>
        </div>
    );
}

export default ProjectCard;
